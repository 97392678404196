import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import './style.scss'

export default function GalleryImages() {
  const data = useStaticQuery(graphql`
    fragment SanityImage on SanityMainImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }
    {
      allSanityGallery(sort: { order: ASC, fields: priority }) {
        edges {
          node {
            title
            priority
            mainImage {
              ...SanityImage
              alt
            }
          }
        }
      }
    }
  `)
  const allSanityGallery = (data || {}).allSanityGallery

  return (
    <div id="imageWrapper">
      <div id="photos">
        {allSanityGallery.edges
          .filter((gallery) => gallery.node.priority !== null)
          .map(({ node }) => (
            <img
              src={imageUrlFor(buildImageObj(node.mainImage))
                .width(1200)
                //.height(Math.floor((9 / 16) * 1200))
                //.fit('crop')
                .auto('format')
                .url()}
              alt={node.mainImage.alt}
            />
          ))}
      </div>
    </div>
  )
}
