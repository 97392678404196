import React from 'react'
import Layout from '../components/Layout/index'
import GalleryImages from '../components/GalleryImages/index'
import LayoutWhite from 'components/LayoutWhite/index'

export default function schedule() {
  return (
    <LayoutWhite>
      <GalleryImages />
    </LayoutWhite>
  )
}
